import './Components/comps.css'
import Header from './Components/Header/Header';
import Homehero from './Components/Pagehome/Hero/Hero';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

const firebaseConfig = {

  apiKey: "AIzaSyBvo_ZEnFy_nuER9gw9OVPad1CecNuhvZQ",

  authDomain: "jacks-portfolio-ee67b.firebaseapp.com",

  projectId: "jacks-portfolio-ee67b",

  storageBucket: "jacks-portfolio-ee67b.appspot.com",

  messagingSenderId: "858218737428",

  appId: "1:858218737428:web:6e6db8c3ba1e0152c1dc01"

};

initializeApp(firebaseConfig);

function App() {
  return (
    <>
    
      <Router>
    
	      <Header />
	      
	      <Routes>
	      
		      <Route index element={<Homehero />} />
		      
		      <Route path="*" />
	      
	      </Routes>
      
      </Router>
    
    </>
  );
}

export default App;
