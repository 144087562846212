import { ReactComponent as Icon } from '../-Global/Icons/Icon.svg'

const Header = () => {
	return ( 
	
	<header>
	
		<div className="wrapper">
	
			<a href="/"><Icon /></a>
			
			<ul>
			
				<li><a href="/">My Work</a></li>
				<li><a href="/">My Journal</a></li>
				<li><a href="/">About Me</a></li>
				<li><a href="/">Log In</a></li>
			
			</ul>
		
		</div>
	
	</header>
	
	);
}
 
export default Header;