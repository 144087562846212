import { useEffect, useState } from 'react'

import {ReactComponent as Kanji} from './media/Kanji.svg'
import VanillaTilt from 'vanilla-tilt';

import { ReactComponent as Title } from "./media/Title.svg";
import { ReactComponent as HeroLines } from "./media/hero-lines.svg";
import { ReactComponent as SocialDribbble } from "../../-Global/Icons/dribbble.svg";
import { ReactComponent as SocialFacebook } from "../../-Global/Icons/facebook.svg";
import { ReactComponent as SocialInstagram } from "../../-Global/Icons/instagram.svg";
import { ReactComponent as SocialYoutube } from "../../-Global/Icons/youtube.svg";
import { getFirestore, collection, getDocs } from 'firebase/firestore';


const Homehero = () => {

	useEffect( () => {
	
		VanillaTilt.init(document.querySelector(".flow"), {
			max: 5,
			speed: 200,
			reset: true,
			perspective: 1000,
			reverse: true,
			'full-page-listening': true
		});
	
	}, [] );
	
	// Get data *********************************
	
	const [hero, setHero] = useState(null);
	const db = getFirestore();
	const colRef = collection(db, 'homepage');
	let colDocs = []
	
	useEffect( () => {
	
		getDocs(colRef)
		.then((snapshot) => {
			snapshot.docs.forEach((doc) => {
				colDocs.push({ ...doc.data(), id: doc.id })
			})
			setHero(colDocs[0]);
			console.log(colDocs[0]);
		})
		.catch(err => {
			console.log(err.message);
		});
	
	}, [] );
	
	//  Return *********************************

	return ( 
	
	<>
	
		<div className="jp__hero">
		
			<div className="wrapper">
			
				<div className="left">
				
					<div className="info">
					
						<p>Current Time ---{'>'} 21:09</p>
						
						<hr />
						
						<p>Sites Live --{'>'} 52</p>
					
					</div>
					
					<div className="title">
					
						<div className="desc">
						
							<h2>{hero ? hero['hero_title'] : null}</h2>
							
							<p>{ hero ? hero['hero_desc'] : null }</p>
						
						</div>
						
						<Title />
					
					</div>
				
				</div>
			
				<div className="right">
				
					<div className="flow">
						
						<Kanji />
						
						<div className="lines_wrap">
						
							<HeroLines />
						
						</div>
					
					</div>
					
					<hr />
					
					<div className="bot">
					
						<ul>
						
							<li> <SocialFacebook /> </li>
							<li> <SocialInstagram /> </li>
							<li> <SocialYoutube /> </li>
							<li> <SocialDribbble /> </li>
						
						</ul>
						
						<p>Est. 1995</p>
					
					</div>
				
				</div>
				
			</div>
		
		</div>
	
	</>
	
	);
}
 
export default Homehero;